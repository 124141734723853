import { UUID } from "crypto"

export const routes = {
  forgotPassword: "/forgot-password/",
  home: "/clients/",
  clients: {
    home: "/clients/",
    item: (clientId: UUID, qs?: Record<string, string>) =>
      `/clients/?${new URLSearchParams({ clientId, ...qs }).toString()}`,
  },
  companies: { home: "/companies/" },
  cybercheck: {
    applications: "/cybercheck/applications/",
    identities: "/cybercheck/identities/",
    devices: "/cybercheck/devices/",
  },
  reports: {
    home: "/reports/",
    item: {
      home: (reportId: UUID, qs?: Record<string, string>) =>
        `/reports/?${new URLSearchParams({ reportId, ...qs }).toString()}`,
      preview: (reportId: UUID, qs?: Record<string, string>) =>
        `/reports/preview/?${new URLSearchParams({ reportId, ...qs }).toString()}`,
    },
  },
  integrations: "/integrations/",
  mfa: "/mfa/",
  resetPassword: "/reset-password/",
  signIn: "/sign-in/",
  apiKeys: {
    home: "/api-keys/",
    create: "/api-keys/create/",
  },
  callback: "/callback/",
  settings: { home: "/settings/" },
} as const
