import { LoaderSpinner } from "@cyna/components/base/Loader"
import { Typo } from "@cyna/components/base/Typo"
import { cn } from "@cyna/components/lib/utils"
import { useIsFetching, useIsMutating } from "@tanstack/react-query"
import { HTMLAttributes, ReactNode } from "react"

export const PageTitle = ({
  children,
  cta,
  className,
  ...otherProps
}: HTMLAttributes<HTMLHeadingElement> & {
  cta?: ReactNode
}) => {
  const isFetching = Boolean(useIsFetching())
  const isMutating = Boolean(useIsMutating())

  return (
    <div
      className={cn(
        "bg-background text-foreground/90 sticky top-0 z-10 flex w-full max-w-4xl flex-col items-center justify-between gap-4 border-b px-4 py-3 sm:static sm:flex-row sm:border-b-0 sm:pb-8 sm:pt-12",
        className,
      )}
    >
      <div className="flex gap-4 sm:items-center sm:pl-12">
        <Typo as="h1" {...otherProps}>
          <h1>{children}</h1>
        </Typo>
        {(isFetching || isMutating) && <LoaderSpinner />}
      </div>
      {cta}
    </div>
  )
}

export const PageSubTitle = ({
  children,
  ...otherProps
}: HTMLAttributes<HTMLHeadingElement>) => (
  <Typo
    as="h2"
    className="bg-background flex w-full max-w-4xl items-center gap-4 pl-4 pt-3 sm:pl-16 sm:pt-0"
    {...otherProps}
  >
    <h2>{children}</h2>
  </Typo>
)

export const PageContent = ({
  className,
  children,
  ...otherProps
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "relative flex max-w-6xl grow flex-col gap-6 px-4 pb-4 sm:pl-16",
      className,
    )}
    {...otherProps}
  >
    {children}
  </div>
)

export const Page = ({
  title,
  subtitle,
  children,
  className,
  cta,
}: {
  title?: ReactNode
  subtitle?: ReactNode
  cta?: ReactNode
  children: ReactNode
  className?: string
}) => (
  <div
    className={cn(
      "h-screen max-h-screen min-h-screen overflow-auto",
      className,
    )}
  >
    <div className="flex flex-col">
      {title && <PageTitle cta={cta}>{title}</PageTitle>}
      {subtitle && <PageSubTitle>{subtitle}</PageSubTitle>}
    </div>
    <PageContent>{children}</PageContent>
  </div>
)
